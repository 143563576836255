import React, { Suspense, lazy, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import StickyMenu from "@containers/sticky-menu";
import PageHeader from "@containers/page-header/layout-01";
import IntroAreaOne from "@components/introarea/layout-4";
import BoxSection from "@components/BoxSection/layout-one/layout-1";
import LocalizeGrid from "@components/localize-grid/layout-4";
import IntroAreaReverse from "@components/introareareverse/layout-5";
import IntroAreaReverseCaptions from "@components/introareareverse/layout-1";
import FeatureKeyBenefits from '@containers/accessibility-solutions/key-benifits/layout-2';
import KeyBenefits from '@containers/accessibility-solutions/key-benifits/layout-1';
import BenefitsBoxSection from "@components/BoxSection/layout-one/layout-2";
import BoxSectionOne from "@components/BoxSection/layout-three/layout-2";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import UseScroll from '@containers/scroll';
import QuoteForm from "@containers/translation-quote-form";
import ContactArea from "@containers/contact-us";
import FeatureGrid from "@components/feature-grid";
const Footer =lazy(()=>import("@layout/footer/layout-01"))
const AccessibilityContentPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const content = normalizedData(data?.page?.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 100) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
       <Seo
        title="Accessibility Content"
        description="Get your products and services to the people who need them with Andovar’s AI-powered Accessibility Solutions translation and localization solutions. Free Consultation."
        noindex={true}
        />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <StickyMenu data={content["sticky-menu-data"]} />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["accessibility-page-header-section"]} />
        <QuoteForm />
        {
          isMobile && showRestComponents ?<>
          <FeatureGrid data={content["accessibility-features"]} />
         <IntroAreaOne layout={3} data={content["accessibility-intro-body"]} /> 
          </> 
          : !isMobile &&<>
           <FeatureGrid data={content["accessibility-features"]} />
         <IntroAreaOne layout={3} data={content["accessibility-intro-body"]} />
          </>
        }
        {
          showRestComponents && <>
            <BoxSection layout={5} data={content["accessibility-use-cases-body"]} />
            <LocalizeGrid layout={1}  data={content["empowering-global-voice"]} />
            <IntroAreaReverse layout={4} data={content["accessibility-multilingual-body"]} />
            <FeatureKeyBenefits layout={1} data={content['accessibility-empowering-brands']} />
            <IntroAreaOne layout={4} data={content["accessibility-transform-text-data"]} />
            <BenefitsBoxSection layout={9} data={content["accessibility-audio-solutions-body"]} />
            <BoxSectionOne
              layout={6}
              data={content["accessibility-tailored-voice-solutions-data"]}
            />
            <IntroAreaOne layout={1} data={content["accessibility-video-content-data"]} />
            <IntroAreaReverseCaptions layout={4} data={content["accessibility-engagement-body"]} />
            <KeyBenefits layout={1} data={content['accessibility-inclusive-text-data']} />
            <CaseStudy layout={3} data={caseStudiesData} />
            <ContactArea Layout={2} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};
export const query = graphql`
  query AccessibilityContentPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "accessibility-content" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;
AccessibilityContentPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default AccessibilityContentPage;
